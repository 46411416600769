<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '620px' }"
    header="Fomulário de Tipo de Gestão"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="grid fluid">
      <div class="col-12 md:col-12 field">
        <label for="description">Descrição</label>
        <InputText
          id="description"
          v-model="v$.obj.descricao.$model"
          maxlength="100"
          placeholder="Informe a descrição do tipo de gestão"
          :class="{ 'p-invalid': submitted && v$.obj.descricao.$invalid }"
        />
        <small class="p-error" v-if="submitted && v$.obj.descricao.$invalid"
          >Descrição da gestão é obrigatório.</small
        >
      </div>
    </div>

    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.obj.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Gestao from "../../../../src/models/gestao";

//Services
import GestaoService from "../../../../src/service/gestao/gestao_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["objSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      obj: new Gestao(),
      submitted: false,
      service: new GestaoService(),
      types: [],
    };
  },
  mounted() {},
  validations() {
    return {
      obj: new Gestao().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.gestao.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.gestao.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.obj.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.submitted = true;
      this.service
        .create(this.obj)
        .then((data) => {
          if (data.status === 201) {
            this.$msgSuccess(data);
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.submitted = true;
      this.service
        .update(this.obj)
        .then((data) => {
          if (data.status === 200) {
            this.$msgSuccess(data);
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.obj = new Gestao();
      this.submitted = false;
      this.$emit("findAll");
      this.visibleDialog = false;
    },
    getData() {
      this.obj = this.objSelected;
    },
  },
};
</script>
<style scoped></style>
